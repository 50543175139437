<template>
  <RenderInstructionRuleCollection
    ref="collection"
    :service="service"
    :edit-fields="additionalFields"
    :columns="columns"
  >
    <template #addSlot>
      <CroppingRuleForm
        :add="true"
        :edit="false"
        :duplicate="false"
        :organization="collection.selectedOrganization"
        @closedialogs="closeDialogs"
      />
    </template>
    <template #editSlot>
      <CroppingRuleForm
        :add="false"
        :edit="true"
        :duplicate="false"
        :selected-rule="collection.selectedItems[0].data"
        @closedialogs="closeDialogs"
      />
    </template>
    <template #duplicateSlot>
      <CroppingRuleForm
        :add="false"
        :edit="false"
        :duplicate="true"
        :selected-rule="collection.selectedItems[0].data"
        @closedialogs="closeDialogs"
      />
    </template>
  </RenderInstructionRuleCollection>
</template>

<script>
import RenderInstructionService from "@/services/RenderInstructionService"
import { ref, reactive } from "vue"

export default {
  setup() {
    const service = new RenderInstructionService("ricroppings")
    const additionalFields = reactive({ masked: false, cropped: false })
    const columns = reactive([
      { field: "crop", header: "Crop", width: "5rem", icon: "crop" },
      { field: "mask", header: "Mask", width: "5rem", icon: "transitionMasked" },
    ])
    const collection = ref()

    function closeDialogs() {
      collection.value.addDialog = false
      collection.value.editDialog = false
      collection.value.duplicateDialog = false
      collection.value.reload()
    }

    return {
      service,
      additionalFields,
      columns,
      collection,
      closeDialogs,
    }
  },
}
</script>
